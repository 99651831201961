import { Room } from 'livekit-client';
import livekitApi from '../api/livekit';

class LiveKitService {
  constructor() {
    this.room = null;
  }

  async createRoom(userHandle) {
    try {
      if (!process.env.VUE_APP_LIVEKIT_URL) {
        throw new Error('LiveKit URL not configured');
      }
      console.log('LiveKit URL:', process.env.VUE_APP_LIVEKIT_URL);
      
      const cleanHandle = userHandle.replace(/^@/, '');
      console.log('Attempting to create room for:', cleanHandle);
      
      const response = await livekitApi.createRoom(cleanHandle);
      console.log('LiveKit API Response:', response.data);
      
      const { token } = response.data;
      if (!token || typeof token !== 'string') {
        console.error('Invalid token format:', token);
        throw new Error('Invalid token received from server');
      }
      
      console.log('Connecting with token:', token.substring(0, 20) + '...');
      this.room = new Room();
      await this.room.connect(process.env.VUE_APP_LIVEKIT_URL, token);
      
      return {
        url: `${window.location.origin}/live/${userHandle}`,
        room: this.room
      };
    } catch (error) {
      console.error('Failed to create room:', error);
      throw error;
    }
  }

  async joinRoom(userHandle, participantName) {
    try {
      if (!process.env.VUE_APP_LIVEKIT_URL) {
        throw new Error('LiveKit URL not configured');
      }
      
      const response = await livekitApi.joinRoom(userHandle, participantName);
      const { token } = response.data;
      
      if (!token || typeof token !== 'string') {
        console.error('Invalid token format:', token);
        throw new Error('Invalid token received from server');
      }

      this.room = new Room();
      await this.room.connect(process.env.VUE_APP_LIVEKIT_URL, token);
      
      return { room: this.room };
    } catch (error) {
      console.error('Failed to join room:', error);
      throw error;
    }
  }

  disconnect() {
    if (this.room) {
      this.room.disconnect();
      this.room = null;
    }
  }
}

export default new LiveKitService(); 