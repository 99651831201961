<template>
  <div class="video-create">
    <!-- Loading Overlay -->
    <div v-if="$store.state.videos.loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    
    <div class="video-content">
      <!-- Video Player Section -->
      <div v-if="!playerError && videoDetails?.playbackId" class="video-player-section">
        <div v-if="isLoading" class="loading-overlay">
          <div class="loading-spinner"></div>
        </div>
        <mux-player
          :playback-id="videoDetails.playbackId"
          stream-type="on-demand"
          :metadata="{ 
            video_id: videoDetails.id_video,
            video_title: videoDetails.title 
          }"
          :aspect-ratio="videoDetails.aspectRatio || '16:9'"
          :duration="videoDetails.duration || null"
          :width="videoDetails.width || null"
          :height="videoDetails.height || null"
          @error="handlePlayerError"
          @playing="handlePlaying"
          @ended="handleEnded"
        >
        </mux-player>
        <button class="custom-button" @click="openClipsTab">
          <i class="fas fa-cut"></i>
        </button>
      </div>

      <!-- Upload Section -->
      <div v-else class="upload-section">
        <div id="upload-container">
          <mux-uploader
            ref="muxUploader"
            class="upload-box"
            :class="{ 'drag-over': isDragging }"
            :endpoint="muxUploadUrl"
            :max-file-size="2 * 1024 * 1024 * 1024"
            accept="video/mp4,video/quicktime,video/x-msvideo"
          >
            <div class="upload-icon">
              <svg xmlns="http://www.w3.org/2000/svg" class="upload-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
            </div>
            <h2>Select video file to upload</h2>
            <p class="upload-text">Or drag and drop video file</p>
            
            <button type="button" class="select-button">Select File</button>
            
            <div v-if="isUploading" class="upload-progress">
              <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
              <span>{{ uploadProgress }}%</span>
            </div>

            <p class="upload-hint">Minimize processing time</p>
            <p class="upload-support">Supported video files are MP4, MOV, AVI</p>
          </mux-uploader>
        </div>
      </div>

      <!-- Editors Section -->
      <div class="editors-section">
        <editor-content :editor="titleEditor" class="title-input" />
        <editor-content :editor="subtitleEditor" class="subtitle-input" />
        <editor-content :editor="descriptionEditor" class="description-input" />
      </div>
    </div>

    <div v-if="showVideoModal" class="video-modal" :class="{ 'show': showVideoModal }">
      <div class="modal-content">
        <div class="modal-header flex items-center justify-between">
          <button @click="showVideoModal = false" class="close-button">
            <i class="fas fa-arrow-left"></i>
          </button>
          <div class="tab-container">
            <div class="tab" :class="{ active: activeTab === 'settings' }" @click="activeTab = 'settings'">Video Settings</div>
            <div class="tab" :class="{ active: activeTab === 'transcript' }" @click="activeTab = 'transcript'">Transcript</div>
            <div class="tab" :class="{ active: activeTab === 'clips' }" @click="activeTab = 'clips'">Clips</div>
          </div>
          <div></div> <!-- Empty div for spacing -->
        </div>
        <div class="modal-body">
          <div class="tab-content">
            <div v-if="activeTab === 'settings'">
              <div class="settings-content">
                <h3>Replace Video</h3>
                <div class="upload-container">
                  <mux-uploader
                    ref="muxUploader"
                    class="upload-box settings-upload"
                    :class="{ 'drag-over': isDragging }"
                    :endpoint="muxUploadUrl"
                    :max-file-size="2 * 1024 * 1024 * 1024"
                    accept="video/mp4,video/quicktime,video/x-msvideo"
                    @success="handleUploadEnd"
                    @error="handleUploadError"
                    @progress="handleUploadProgress"
                    @start="handleUploadStart"
                  >
                    <div class="upload-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" class="upload-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>
                    </div>
                    <h2>Replace current video</h2>
                    <p class="upload-text">Drag and drop or click to upload</p>
                    
                    <button type="button" class="select-button">Select File</button>
                    
                    <div v-if="isUploading" class="upload-progress">
                      <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
                      <span>{{ uploadProgress }}%</span>
                    </div>
                  </mux-uploader>
                </div>
              </div>
            </div>
            <div v-if="activeTab === 'transcript'">
              <!-- Transcript content goes here -->
            </div>
            <div v-if="activeTab === 'clips'">
              <!-- Clips content goes here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@mux/mux-uploader'
import '@mux/mux-player'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { mapActions } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  name: 'VideoCreate',
  components: {
    EditorContent
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      titleEditor: null,
      subtitleEditor: null,
      descriptionEditor: null,
      videoDetails: null,
      id_video: null,
      id_course_module: null,
      uploadProgress: 0,
      isUploading: false,
      isDragging: false,
      muxUploadUrl: null,
      isLoading: false,
      currentUploadId: null,
      playerError: false,
      showVideoModal: false,
      activeTab: 'clips'
    }
  },
  async created() {
    try {
      console.log('[MUX] Component created')
      
      if (!this.$store.state.auth.isAuthenticated) {
        this.$router.push('/login')
        throw new Error('Authentication required')
      }

      const id_corporation = this.$store.state.auth.user?.id_corporation
      if (!id_corporation) {
        throw new Error('Corporation ID not found')
      }

      const id_course_module = this.$route.params.id_course_module
      if (!id_course_module) {
        throw new Error('Module ID is required')
      }
      
      // Load and map the initial video details
      const response = await this.$store.dispatch('videos/getVideoByModuleId', id_course_module)
      console.log('[MUX] Raw video details at create:', response.data)
      
      // Map the response data with the same logic as loadVideoDetails
      this.videoDetails = {
        ...response.data,
        video_key: response.data.assetId,  // This is what the player checks for
        playbackId: response.data.playbackId,
        aspectRatio: response.data.aspectRatio,
        duration: response.data.duration,
        width: response.data.maxWidth,
        height: response.data.maxHeight,
        originalName: response.data.originalName
      }
      
      console.log('[MUX] Mapped video details:', this.videoDetails)
      this.id_video = response.data.id_video
      
      // Get both uploadUrl and uploadId from the response
      const { uploadUrl, uploadId } = await this.$store.dispatch('videos/getMuxUploadUrl', {
        id_video: this.id_video,
        id_corporation,
        id_course_module
      })

      this.muxUploadUrl = uploadUrl
      this.currentUploadId = uploadId // Store the upload ID immediately
      console.log('[MUX] Upload URL set:', this.muxUploadUrl)
      console.log('[MUX] Upload ID set:', this.currentUploadId)
    } catch (error) {
      console.error('[MUX] Error in setup:', error)
      this.toast.error(error.message || 'Error initializing video upload')
      if (error.message.includes('Authentication')) {
        this.$router.push('/login')
      }
    }
  },
  beforeMount() {
    this.initializeEditors()
  },
  mounted() {
    console.log('[MUX] Component mounted')
    this.setupMuxUploader()
  },
  beforeUnmount() {
    console.log('[MUX] Component unmounting')
    this.cleanupMuxUploader()
    this.destroyEditors()
  },
  methods: {
    ...mapActions('theme', ['setTheme']),

    redirectToApprendo() {
      window.location.href = 'https://apprendo.io';
    },

    setupMuxUploader() {
      customElements.whenDefined('mux-uploader').then(() => {
        console.log('[MUX] mux-uploader defined')
        const uploader = this.$refs.muxUploader
        
        if (uploader) {
          console.log('[MUX] Attaching event listeners')
          
          // Using event names from Mux documentation
          uploader.addEventListener('success', (event) => {
            console.log('[MUX] Raw success event:', {
              type: event.type,
              detail: event.detail,
              target: event.target,
              response: event.target?.response,
              responseType: event.target?.responseType
            });

            // Try to get response from event.detail first (Mux's documented approach)
            let uploadData = event.detail;

            // If not in detail, try parsing response
            if (!uploadData && event.target?.response) {
              try {
                uploadData = JSON.parse(event.target.response);
                console.log('[MUX] Parsed response data:', uploadData);
              } catch (e) {
                console.error('[MUX] Error parsing response:', e);
              }
            }

            // Log the final data we'll work with
            console.log('[MUX] Upload data to process:', uploadData);

            // Create a normalized event object
            const normalizedEvent = {
              ...event,
              detail: {
                ...event.detail,
                uploadData
              }
            };

            this.handleUploadEnd(normalizedEvent);
          });

          const boundStart = this.handleUploadStart.bind(this);
          const boundError = this.handleUploadError.bind(this);
          const boundProgress = this.handleUploadProgress.bind(this);

          uploader.addEventListener('error', boundError);
          uploader.addEventListener('progress', boundProgress);
          uploader.addEventListener('start', boundStart);

          // Store bound functions for cleanup
          this._boundHandlers = {
            start: boundStart,
            error: boundError,
            progress: boundProgress
          };

          // Add dragover and dragleave events
          uploader.addEventListener('dragover', () => {
            this.isDragging = true;
          });
          
          uploader.addEventListener('dragleave', () => {
            this.isDragging = false;
          });

          console.log('[MUX] Event listeners attached')
        }
      })
    },

    cleanupMuxUploader() {
      const uploader = this.$refs.muxUploader
      if (uploader && this._boundHandlers) {
        console.log('[MUX] Removing event listeners')
        uploader.removeEventListener('success', this.handleUploadEnd)
        uploader.removeEventListener('error', this._boundHandlers.error)
        uploader.removeEventListener('progress', this._boundHandlers.progress)
        uploader.removeEventListener('start', this._boundHandlers.start)
      }
    },

    initializeEditors() {
      const editorConfig = {
        extensions: [StarterKit],
        editorProps: { attributes: { class: '' } }
      }

      this.titleEditor = new Editor({
        ...editorConfig,
        extensions: [
          StarterKit,
          Placeholder.configure({ placeholder: 'Title' })
        ],
        editorProps: { attributes: { class: 'title-editor' } }
      })

      this.subtitleEditor = new Editor({
        ...editorConfig,
        extensions: [
          StarterKit,
          Placeholder.configure({ placeholder: 'Subtitle' })
        ],
        editorProps: { attributes: { class: 'subtitle-editor' } }
      })

      this.descriptionEditor = new Editor({
        ...editorConfig,
        extensions: [
          StarterKit,
          Placeholder.configure({ placeholder: 'Description' })
        ],
        editorProps: { attributes: { class: 'description-editor' } }
      })
    },

    destroyEditors() {
      this.titleEditor?.destroy()
      this.subtitleEditor?.destroy()
      this.descriptionEditor?.destroy()
    },

    handleUploadStart(event) {
      console.log('[MUX] Upload start event:', event);
      this.isUploading = true;
      this.uploadProgress = 0;
      console.log('[MUX] Using upload ID:', this.currentUploadId);
    },

    handleUploadProgress(event) {
      const { progress } = event.detail
      console.log('[MUX] Upload progress:', progress)
      this.uploadProgress = Math.round(progress * 100)
    },

    async handleUploadEnd(event) {
      console.log('[MUX] Processing upload completion:', event);
      try {
        // Use the upload ID we extracted from the URL
        if (!this.currentUploadId) {
          console.error('[MUX] No upload ID found');
          throw new Error('Upload ID not found');
        }

        await this.$store.dispatch('videos/updateVideoAfterUpload', {
          id_video: this.id_video,
          uploadData: {
            status: 'processing',
            fileSize: event.detail?.file?.size,
            metadata: {
              uploadId: this.currentUploadId,
              contentType: event.detail?.file?.type,
              originalName: event.detail?.file?.name
            }
          }
        });

        this.startVideoStatusPolling();
        this.toast.success('Video uploaded successfully and is being processed');
        this.isUploading = false;
      } catch (error) {
        console.error('[MUX] Upload completion error:', error);
        this.toast.error(error.message || 'Failed to update video status');
        this.isUploading = false;
      }
    },

    handleUploadError(event) {
      console.error('[MUX] Upload error:', event.detail)
      this.isUploading = false
      this.uploadProgress = 0
      this.toast.error(event.detail?.message || 'Upload failed')
    },

    async loadVideoDetails() {
      try {
        const response = await this.$store.dispatch(
          'videos/getVideoByModuleId', 
          this.$route.params.id_course_module
        );
        
        console.log('[MUX] Raw video response:', response.data);
        
        // Map the response data directly since values are already at root level
        this.videoDetails = {
          ...response.data,
          video_key: response.data.assetId,  // This is what the player checks for
          playbackId: response.data.playbackId,
          aspectRatio: response.data.aspectRatio,
          duration: response.data.duration,
          width: response.data.maxWidth,
          height: response.data.maxHeight,
          originalName: response.data.originalName
        };
        
        console.log('[MUX] Video metadata:', response.data.metadata);
        console.log('[MUX] Mapped video details:', this.videoDetails);
      } catch (error) {
        console.error('Error loading video details:', error);
        this.toast.error('Failed to load video details');
      }
    },

    handlePlayerError(error) {
      console.error('[MUX] Player error:', error);
      this.toast.error('An error occurred during video playback');
      this.playerError = true; // Set the playerError flag to true
    },

    handlePlaying() {
      console.log('[MUX] Video playing')
    },

    handleEnded() {
      console.log('[MUX] Video ended')
    },

    async startVideoStatusPolling() {
      console.log('[MUX] Starting video status polling');
      let attempts = 0;
      const maxAttempts = 30;

      const pollInterval = setInterval(async () => {
        try {
          attempts++;
          console.log(`[MUX] Polling attempt ${attempts}/${maxAttempts}`);

          if (!this.currentUploadId) {
            console.error('[MUX] No upload ID available for polling');
            clearInterval(pollInterval);
            return;
          }

          const uploadStatus = await this.$store.dispatch(
            'videos/checkUploadStatus',
            this.currentUploadId
          );

          console.log('[MUX] Upload status:', uploadStatus);

          if (uploadStatus.status === 'asset_created' && uploadStatus.assetId) {
            const assetStatus = uploadStatus.assetStatus || 'processing';

            await this.$store.dispatch('videos/updateVideoAfterUpload', {
              id_video: this.id_video,
              uploadData: {
                muxAssetId: uploadStatus.assetId,
                status: assetStatus,
                metadata: {
                  uploadId: this.currentUploadId,
                  playbackId: uploadStatus.playbackId,
                  aspectRatio: uploadStatus.aspectRatio,
                  duration: uploadStatus.duration,
                  width: uploadStatus.maxWidth,
                  height: uploadStatus.maxHeight,
                  processingCompletedAt: new Date().toISOString(),
                  muxAssetId: uploadStatus.assetId
                }
              }
            });

            if (assetStatus === 'ready') {
              console.log('[MUX] Processing complete, stopping poll');
              clearInterval(pollInterval);
              this.toast.success('Video processing complete');
              await this.loadVideoDetails();
              return;
            }
          }

          if (attempts >= maxAttempts) {
            console.log('[MUX] Max polling attempts reached');
            clearInterval(pollInterval);
            this.toast.warning('Video processing is taking longer than expected');
            await this.loadVideoDetails();
          }
        } catch (error) {
          console.error('[MUX] Error during polling:', error);
          clearInterval(pollInterval);
          this.toast.error('Error checking video status');
        }
      }, 10000);

      // Store interval ID for cleanup
      this.pollIntervalId = pollInterval;
    },

    async setupUploader() {
      try {
        const id_corporation = this.$store.state.auth.corporation?.id_corporation;
        
        // Get the upload URL from Mux
        const { uploadUrl } = await this.$store.dispatch('videos/getMuxUploadUrl', {
          id_video: this.id_video,
          id_corporation,
          id_course_module: this.$route.params.id_course_module
        });

        // Extract upload_id from the URL
        const urlParams = new URL(uploadUrl).searchParams;
        const uploadId = urlParams.get('upload_id');
        
        console.log('[MUX] Extracted upload ID:', uploadId);
        
        // Store the upload ID
        this.currentUploadId = uploadId;

        // Set the upload URL on the uploader
        if (this.$refs.muxUploader) {
          this.$refs.muxUploader.endpoint = uploadUrl;
        }

        return uploadUrl;
      } catch (error) {
        console.error('[MUX] Error setting up uploader:', error);
        this.toast.error('Failed to initialize upload');
        throw error;
      }
    },

    openClipsTab() {
      this.activeTab = 'clips';
      this.showVideoModal = true;
    },
  }
}
</script>

<style scoped>
.video-create {
  --local-bg: #ffffff;
  --local-text: #2c3e50;
  background-color: var(--local-bg);
  color: var(--local-text);
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.upload-box {
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  background-color: var(--bg-secondary);
  transition: all 0.3s ease;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-box:hover {
  border-color: #666;
}

.upload-box.drag-over {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.05);
  transform: scale(1.02);
}

.upload-icon {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-svg {
  width: 64px;
  height: 64px;
  color: #007bff;
  opacity: 1;
}

.select-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 1rem 0;
  font-size: 1rem;
}

.select-button:hover {
  background-color: #0056b3;
}

.upload-progress {
  margin-top: 1rem;
  width: 100%;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 20px;
}

.progress-bar {
  background: #007bff;
  height: 100%;
  transition: width 0.3s ease;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #007bff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.video-player-section {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 2rem;
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
  background: var(--bg-secondary);
  border-radius: 12px;
  overflow: hidden;
}

.video-player-section mux-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #007bff;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.custom-button i {
  font-size: 1.2rem;
}

.editors-section {
  margin-top: 2rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 12px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Editor Styles */
:deep(.title-editor) {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

:deep(.subtitle-editor) {
  font-size: 1.5em;
  color: #666;
  margin-bottom: 1.5em;
}

:deep(.description-editor) {
  font-size: 1.1em;
  margin-bottom: 1.5em;
}

:deep(.ProseMirror) {
  outline: none;
  min-height: 1.5em;
}

:deep(.ProseMirror p) {
  margin: 0;
}

/* Placeholder Styles */
:deep(.title-editor p.is-editor-empty:first-child::before),
:deep(.subtitle-editor p.is-editor-empty:first-child::before),
:deep(.description-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.select-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 1rem 0;
  font-size: 1rem;
}

.select-button:hover {
  background-color: #0056b3;
}

.video-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 9999;
}

.video-modal.show {
  transform: translateX(0);
}

.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  @apply flex items-center justify-between px-4 py-3 border-b border-gray-200;
}

.close-button {
  @apply text-gray-600 hover:text-gray-800 focus:outline-none;
}

.close-button i {
  @apply text-xl;
}

.tab-container {
  @apply flex space-x-4;
}

.tab {
  @apply px-4 py-2 text-sm font-medium text-gray-500 cursor-pointer;
}

.tab.active {
  @apply text-blue-600 border-b-2 border-blue-600;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.settings-content {
  padding: 20px;
}

.settings-content h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.settings-upload {
  min-height: 200px !important;
  padding: 1.5rem !important;
}

.upload-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>