<template>
  <div class="live-room">
    <!-- Owner's View (Start Conversation) -->
    <div v-if="isOwner && !isConnected" class="room-status">
      <h2>Start your Live Conversation</h2>
      <button @click="startRoom" class="start-button">
        Start Conversation
      </button>
    </div>

    <!-- Guest's View (Join Conversation) -->
    <div v-if="!isOwner && !isConnected" class="join-container">
      <h2>Join @{{ ownerHandle }}'s Conversation</h2>
      <div class="permission-checks">
        <div class="permission-item">
          <i class="fas fa-video" :class="{ 'text-success': hasCamera }"></i>
          <span>Camera {{ hasCamera ? 'Ready' : 'Not Available' }}</span>
        </div>
        <div class="permission-item">
          <i class="fas fa-microphone" :class="{ 'text-success': hasMic }"></i>
          <span>Microphone {{ hasMic ? 'Ready' : 'Not Available' }}</span>
        </div>
      </div>
      <button @click="joinRoom" class="join-button" :disabled="!canJoin">
        Join Conversation
      </button>
    </div>

    <!-- Active Conversation View (Both Owner and Guest) -->
    <div v-if="isConnected" class="conference-container">
      <div class="video-grid">
        <participant-video 
          v-for="participant in participants" 
          :key="participant.sid"
          :participant="participant"
        />
      </div>
      
      <div class="controls">
        <button @click="toggleMic">
          <i :class="isMuted ? 'fas fa-microphone-slash' : 'fas fa-microphone'" />
        </button>
        <button @click="toggleVideo">
          <i :class="isVideoOff ? 'fas fa-video-slash' : 'fas fa-video'" />
        </button>
        <button @click="endCall" class="end-call">
          <i class="fas fa-phone-slash" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LiveRoom',
  setup() {
    const store = useStore()
    const route = useRoute()
    const roomUrl = ref(null)
    
    const isOwner = computed(() => {
      const userHandle = route.params.userHandle;
      return store.state.livekit.isOwner && store.state.livekit.ownerHandle === userHandle;
    });

    const ownerHandle = computed(() => route.params.userHandle);
    
    const hasCamera = ref(false);
    const hasMic = ref(false);
    
    const canJoin = computed(() => hasCamera.value && hasMic.value);

    const startRoom = async () => {
      const userHandle = route.params.userHandle
      await store.dispatch('livekit/createOrJoinRoom', userHandle)
    }

    const joinRoom = async () => {
      const userHandle = route.params.userHandle
      await store.dispatch('livekit/createOrJoinRoom', userHandle)
    }

    onMounted(async () => {
      try {
        const userHandle = route.params.userHandle
        const result = await store.dispatch('livekit/createOrJoinRoom', userHandle)
        if (result?.url) {
          roomUrl.value = result.url
        }
      } catch (error) {
        console.error('Failed to setup room:', error)
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        hasCamera.value = true;
        hasMic.value = true;
        stream.getTracks().forEach(track => track.stop());
      } catch (err) {
        console.error('Media permissions error:', err);
      }
    })

    onBeforeUnmount(() => {
      store.dispatch('livekit/disconnect')
    })

    return {
      roomUrl,
      isConnected: computed(() => store.state.livekit.isConnected),
      error: computed(() => store.state.livekit.error),
      isOwner,
      ownerHandle,
      hasCamera,
      hasMic,
      canJoin,
      startRoom,
      joinRoom
    }
  }
})
</script>

<style scoped>
.live-room {
  height: 100vh;
  background: #1a1a1a;
  color: white;
  display: flex;
  flex-direction: column;
}

.conference-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.video-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
  min-height: 0;
}

.room-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1rem;
}

.start-button {
  padding: 1rem 2rem;
  background: #007bff;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
}

.room-link {
  background: rgba(255,255,255,0.1);
  padding: 1rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.link-input {
  width: 100%;
  padding: 0.5rem;
  background: rgba(0,0,0,0.2);
  border: 1px solid rgba(255,255,255,0.1);
  color: white;
  border-radius: 4px;
}

.controls {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  background: rgba(0,0,0,0.5);
  padding: 1rem;
  border-radius: 999px;
}

.controls button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background: rgba(255,255,255,0.1);
  color: white;
  cursor: pointer;
}

.end-call {
  background: #dc3545 !important;
}

.join-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.video-preview {
  width: 100%;
  aspect-ratio: 16/9;
  background: rgba(0,0,0,0.2);
  border-radius: 12px;
  overflow: hidden;
}

.permission-checks {
  display: flex;
  gap: 2rem;
}

.permission-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.text-success {
  color: #28a745;
}

.join-button {
  padding: 1rem 2rem;
  background: #007bff;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
}

.join-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style> 