import LiveKitService from '@/services/LiveKitService'

export default {
  namespaced: true,
  state: {
    currentRoom: null,
    error: null,
    isConnected: false,
    isOwner: false,
    ownerHandle: null
  },
  mutations: {
    SET_ROOM(state, room) {
      state.currentRoom = room
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    SET_CONNECTED(state, status) {
      state.isConnected = status
    },
    SET_OWNER(state, { isOwner, ownerHandle }) {
      state.isOwner = isOwner
      state.ownerHandle = ownerHandle
    }
  },
  actions: {
    async createOrJoinRoom({ commit, rootState }, userHandle) {
      try {
        console.log('Original userHandle:', userHandle);
        const cleanHandle = userHandle.replace(/^@/, '');
        console.log('Cleaned userHandle:', cleanHandle);
        console.log('Current user:', rootState.auth.user);
        
        // Check if current user is the handle owner
        const isOwner = rootState.auth.user?.unique_handler === cleanHandle;
        console.log('Is owner Conversation:', isOwner);
        
        commit('SET_OWNER', { isOwner, ownerHandle: cleanHandle })
        
        let result
        if (isOwner) {
          result = await LiveKitService.createRoom(cleanHandle)
        } else {
          const participantName = rootState.auth.user?.unique_handler || 'guest'
          result = await LiveKitService.joinRoom(cleanHandle, participantName)
        }

        commit('SET_ROOM', result.room)
        commit('SET_CONNECTED', true)
        return result
      } catch (error) {
        commit('SET_ERROR', error.message)
        throw error
      }
    }
  }
} 