import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '@/views/LandingPage.vue'
import Home from '@/views/HomePage.vue'
import SignIn from '@/views/SignIn.vue'
import SignUp from '@/views/SignUp.vue'
import VideoCreate from '@/views/VideoCreate.vue'
import { useStore } from 'vuex'
import LiveRoom from '@/components/LiveRoom.vue'

const routes = [
  { path: '/', name: 'LandingPage', component: LandingPage,meta:{title:'Apprendo | Grow Your Brand & Monetize Your Expertise'} },
  { path: '/home', name: 'HomePage', component: Home, meta: { requiresAuth: true } },
  { path: '/signin', name: 'SignIn', component: SignIn },
  { path: '/signup', name: 'SignUp', component: SignUp },
  { 
    path: '/@:username', 
    component: () => import('@/views/UserProfile.vue'),
    children: [
      { path: '', name: 'UserActivity', component: () => import('@/components/UserActivity.vue') },
      { path: 'posts', name: 'UserPosts', component: () => import('@/components/UserPosts.vue') },
      { path: 'likes', name: 'UserLikes', component: () => import('@/components/UserLikes.vue') }
    ]
  },
  // Add other routes here
  {
    path: '/auth/email-signin-complete',
    name: 'EmailSignInComplete',
    component: () => import('@/views/EmailSignInComplete.vue')
  },
  {
    path: '/video/create/',
    redirect: '/dashboard'  // Redirect base path without ID
  },
  {
    path: '/video/create/:id_course_module(\\d+)',
    name: 'VideoCreate',
    component: VideoCreate,
    props:true,
    meta: { requiresAuth: true }
  },
  {
    path: '/video/create/:invalidId',  // Catch invalid IDs
    redirect: '/dashboard'
  },
  {
    path: '/live/:userHandle',
    name: 'LiveRoom',
    component: LiveRoom,
    meta: {
      requiresAuth: true,
      title: 'Live Session'
    }
  }
]

const requireAuth = (to, from, next) => {
  const store = useStore();
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/isAuthenticated']) {
    next({
      path: '/signin',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(requireAuth);

router.beforeEach((to, from, next) => {
  // If the route has a title in meta, set it as the document title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
