import api from './index'

export default {
  createRoom(userHandle) {
    console.log('Creating room for handle:', userHandle);
    const roomName = `room_${userHandle}`
    return api.post('/livekit/token', { roomName, userHandle }).catch(error => {
      console.error('LiveKit API Error:', {
        status: error.response?.status,
        data: error.response?.data,
        headers: error.response?.headers
      });
      throw error;
    });
  },

  joinRoom(userHandle, participantName) {
    const roomName = `room_${userHandle}`
    return api.post('/livekit/token', { 
      roomName,
      userHandle,
      participantName 
    })
  }
} 