import { createStore } from 'vuex'
import users from './modules/users'
import auth from './modules/auth'
import videos from './modules/videos'
import livekit from './modules/livekit'

export default createStore({
  modules: {
    users,
    auth,
    videos,
    livekit
  }
})
